<template>
  <div class="guide">
    <Normaltop
      :url="qiniuUrl"
      title="远程智慧监考"
      en="Remote intelligent invigilator"
    ></Normaltop>
    <div class="guide-cont">
      <div class="part partone">
        <el-card>
          <h3 class="part-h3">远程智慧监考平台</h3>
          <p class="part-cont">
            网培师各模块的评测考试全部通过在线方式进行，每周定时开放。
            <br />正式考试的时候，所有报名参加评测的人员都需要经过远程身份验证，并在带摄像头的PC电脑上进行考试。
            <br />网培师的各个评测模块，每周都将定时开放考试，报名后可自主选择考试时间。
            <br />此外，应试者可以在不同的日期参加不同的评测模块考试。
            <br />本项目的远程考试系统采用网班教育提供的“智慧监考”平台，报名后，需在指定时间从PC电脑登录进考试系统，准备可自己的身份证信息，平台将进行AI人脸识别，远程确认身份后，开始考试。开始过程中，会随机进行取样，确保考试的公平公正。
          </p>
        </el-card>
      </div>
      <div class="part parttwo">
        <h3 class="part-h3">参与评测考试流程图</h3>
        <!-- <el-row>
          <el-col :span="6">
            <div class="flex-col-center">
              <div>
                <img src="../assets/images/guide/img2.png" alt="" srcset="" />
              </div>
              <div class="right">
                <img src="../assets/images/guide/right.png" alt="" srcset="" />
              </div>
            </div>
            <p class="part-desc">在线报名（付费）</p>
          </el-col>

          <el-col :span="6">
            <div class="flex-col-center">
              <div>
                <img src="../assets/images/guide/img7.png" alt="" srcset="" />
              </div>
              <div class="right">
                <img src="../assets/images/guide/right.png" alt="" srcset="" />
              </div>
            </div>
            <p class="part-desc">选定日期<br />PC电脑登录考试系统</p>
          </el-col>

          <el-col :span="6">
            <div class="flex-col-center">
              <div>
                <img src="../assets/images/guide/img8.png" alt="" srcset="" />
              </div>
              <div class="right">
                <img src="../assets/images/guide/right.png" alt="" srcset="" />
              </div>
            </div>
            <p class="part-desc">完成人脸远程身份验证</p>
          </el-col>

          <el-col :span="6">
            <div class="flex-col-center">
              <div>
                <img src="../assets/images/guide/img3.png" alt="" srcset="" />
              </div>
              <div class="hide-right right">
                <img src="../assets/images/guide/right.png" alt="" srcset="" />
              </div>
            </div>

            <p class="part-desc">开始并完成评测考试</p>
          </el-col>
        </el-row> -->
        <el-row :gutter="20" class="flowchart">
          <el-col :span="4">
             <img src="../assets/images/guide/img2.png" alt="" srcset="" />
          </el-col>

          <el-col :span="2"><img src="../assets/images/guide/right.png" alt="" srcset="" /></el-col>

          <el-col :span="4">
             <img src="../assets/images/guide/img2.png" alt="" srcset="" />
          </el-col>

          <el-col :span="2"><img src="../assets/images/guide/right.png" alt="" srcset="" /></el-col>

          <el-col :span="4">
             <img src="../assets/images/guide/img2.png" alt="" srcset="" />
          </el-col>

          <el-col :span="2"><img src="../assets/images/guide/right.png" alt="" srcset="" /></el-col>

          <el-col :span="4">
             <img src="../assets/images/guide/img2.png" alt="" srcset="" />
          </el-col>
        </el-row>

        <el-row :gutter="20" class="flowtxt">
          <el-col :span="6">
            <p class="part-desc">在线报名（付费）</p>
          </el-col>

          <!-- <el-col :span="2"></el-col> -->

          <el-col :span="6">
             <p class="part-desc">选定日期<br />PC电脑登录考试系统</p>
          </el-col>

          <!-- <el-col :span="2"></el-col> -->

          <el-col :span="6">
            <p class="part-desc">完成人脸远程身份验证</p>
          </el-col>

          <!-- <el-col :span="2"></el-col> -->

          <el-col :span="6">
            <p class="part-desc">开始并完成评测考试</p>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Normaltop from '../components/normaltop'
export default {
  name: 'Syllabus',
  components: { Normaltop },
  data() {
    return {
      qiniuUrl: this.$qiniuUrl + 'guide/banner.png',
    }
  },
  created() {},
  methods: {},
}
</script>
<style lang="less" scoped>
.guide {
  font-weight: normal;
  font-size: 16px;
  img {
    width: 100%;
  }
  /deep/.el-button--danger {
    background: #e10133;
    width: 150px;
    color: #fff;
  }
  .guide-cont {
    // width: 70%;
    // min-width: 1200px;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box;
    padding-bottom: 70px;
    .part {
      width: 100%;
      padding: 100px 20%;
      box-sizing: border-box;
      margin: 0 auto;
      position: relative;
      padding-top: 50px;
      /deep/.el-card .el-card__body {
        padding: 50px;
      }
      .part-h3 {
        font-size: 22px;
        padding: 20px 0;
      }
      .part-cont {
        padding-top: 16px;
        color: #333;
        line-height: 2;
      }
      .part-desc {
        font-size: 16px;
        padding-top: 16px;
        color: #333;
        line-height: 2;
        text-align: center;
      }
      .part-indent {
        text-indent: 20px;
      }
      .el-col .flex-col-center {
        div {
          width: 50%;
          text-align: center;
        }
      }
      // .right {
      //   padding: 0 10%;
      //   box-sizing: border-box;
      // }
      // .hide-right {
      //   visibility: hidden;
      // }
      .icon {
        width: 120px;
        height: 120px;
        margin-right: 30px;
      }
      .flowchart{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:40px;
      }
      .flowtxt{
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0px;
      }
    }
    .partone {
      background-image: url('../assets/images/home/part-bg.jpg');
    }
    // .parttwo {
    //   margin: 0 50px;
    // }
  }
}
</style>
